import React,{useState,useEffect,useMemo} from 'react'
import axios from 'axios'
import moment from 'moment';
import Sidebar from './component/Sidebar'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Breadcrumbs, Button, Stack } from '@mui/material';

import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate,useLocation,Navigate } from 'react-router-dom'

const breadcrumbs = [
  <Link underline="hover" key="1" color="inherit" href="/">
    Tableau de bord
  </Link>,

  <Typography key="3">
    Gestion des clients
  </Typography>,
];
/* 
function createData(SIREN, Nom, Prenom, AdresseEmail, Telephone, DateCreation, Actions) {
  return {
    SIREN,
    Nom,
    Prenom,
    AdresseEmail,
    Telephone,
    DateCreation,
    Actions
  };
}

const rows = [
  createData(913605127, 'Rizwan', 'Ahmad', 'rizwan.itpro@gmail.com', '0184791680', 'Friday 13 January 2023', 'action'),
  createData('913605127', 'Manash', 'patidar', 'manash@gmail.com', '0184791680', 'Friday 13 January 2023', 'action'),
]; */

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
 
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/* const headCells = [
  {
    id: 'SIREN',
    numeric: false,
    disablePadding: true,
    label: 'SIREN',
  },
  {
    id: 'Nom',
    numeric: true,
    disablePadding: false,
    label: 'Nom',
  },
  {
    id: 'Prenom',
    numeric: true,
    disablePadding: false,
    label: 'Prénom',
  },
  {
    id: 'AdresseEmail',
    numeric: true,
    disablePadding: false,
    label: 'ADRESSE EMAIL',
  },
  {
    id: 'Telephone',
    numeric: true,
    disablePadding: false,
    label: 'TÉLÉPHONE',
  },
  {
    id: 'DateCreation',
    numeric: true,
    disablePadding: false,
    label: 'Date création',
  },
  {
    id: 'Actions',
    numeric: true,
    disablePadding: false,
    label: 'Actions',
  },
]; */

function EnhancedTableHead(props) {
  const {  order, orderBy, onRequestSort,headCells } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#fff",
      color: "#797979",
      fontSize: '16px',
      fontWeight: '400'
    },

  }));
  return (
    <TableHead >
      <TableRow>

        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'normal' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = { 
  onRequestSort: PropTypes.func.isRequired, 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  let totalRows = props.totalRows;
  const handleSearch = (event) => {
    if(event.target.value.length>0){
      let searchVal = event.target.value;
      const filteredRows = totalRows.filter((row) => {
        
        let email = (row.email).toLowerCase();
        let firstname = (row.firstname).toLowerCase();
        return email.includes(searchVal.toLowerCase()) || firstname.includes(searchVal.toLowerCase())
        
        /* let telephone = (row.telephone).toLowerCase();
        let ville = (row.ville).toLowerCase(); */
       
      });
      props.setRows(filteredRows);
    }else{
      props.setRows(totalRows)

    }
  };
  return (

    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },

      }}
    >
      <Typography
        sx={{ flex: '1 1 100%', marginTop: '15px' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <Paper
         className='xl:w-[400px] w-full'
          component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            inputProps={{ 'aria-label': 'search ' }}
            onInput={handleSearch}
          />

          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" disabled>
            <SearchIcon />
          </IconButton>
        </Paper>
      </Typography>

      {/* <Tooltip title="Filter list">
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip> */}
    </Toolbar>
  );
}

 
export default function Clients() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
 
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [headCells, setHeadCells] = useState([
    {
      id: 'SIREN',
      numeric: false,
      disablePadding: true,
      label: 'SIREN',
    },
    {
      id: 'Nom',
      numeric: true,
      disablePadding: false,
      label: 'Nom',
    },
    {
      id: 'Prenom',
      numeric: true,
      disablePadding: false,
      label: 'Prénom',
    },
    {
      id: 'AdresseEmail',
      numeric: true,
      disablePadding: false,
      label: 'ADRESSE EMAIL',
    },
    {
      id: 'Telephone',
      numeric: true,
      disablePadding: false,
      label: 'TÉLÉPHONE',
    },
    {
      id: 'DateCreation',
      numeric: true,
      disablePadding: false,
      label: 'Date création',
    },
    {
      id: 'Actions',
      numeric: true,
      disablePadding: false,
      label: 'Actions',
    },
  ]);
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState([]);
  // const [visibleRows, setvisibleRows] = useState([]);
  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage,rows],
  );

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  

  async function getAllUsers(){
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/user`);
      
      setRows(response.data.data);
      setTotalRows(response.data.data);
      
    } catch (error) {
      console.error(error);
      
    }
  };

  const currentAdmin = useSelector(state => state.administrator.admin);
  
  if (!currentAdmin) {
    return <Navigate to="/admin/login" />;
  } 
  return (
 
            <div className=' w-full h-full bg-white p-8'>
              <Typography variant="h4" component="h4" fontWeight={500} color={'#1CBE93'}>Gestion des clients</Typography>
              <Stack spacing={2} mb={3} pb={3} sx={{ borderBottom: '0.5px solid rgb(222, 227, 226)' }}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                  {breadcrumbs}
                </Breadcrumbs>

              </Stack>
              <Typography variant="h6" component="h6" fontWeight={400} mb={1} color={'#27295B'}>Tous les utilisateurs</Typography>
              <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                  <EnhancedTableToolbar  setRows={setRows} rows={rows} setTotalRows={setTotalRows} totalRows={totalRows} />
                  <TableContainer sx={{ marginTop: "15px" }}>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                     
                    >
                      <EnhancedTableHead 
                        order={order}
                        orderBy={orderBy} 
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        headCells={headCells}
                      />
                      <TableBody>
                        {visibleRows.map((row, index) => {

                          return (
                            <TableRow
                              hover  
                              role="checkbox" 
                              tabIndex={-1}
                              key={row.name}  
                              sx={{ cursor: 'pointer' }}
                            >
                              <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{row.siren!=="null"?row.siren:""}</TableCell>
                              <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{row.lastname}</TableCell>
                              <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{row.firstname}</TableCell>
                              <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{row.email}</TableCell>
                              <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{row.telephone}</TableCell>
                              <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{moment(row.creation_date).format("LL")}</TableCell>
                              <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                <Button component={Link} to={`/admin/clientdetails/${row.id}`} variant="outlined" color="primary">Afficher</Button>
                                <Button variant="outlined" sx={{ mx: '2px' }} className='hidden'>Modifier</Button>
                                <Button variant="outlined" color="error" sx={{ mx: '2px' }} >Supprimer</Button></TableCell>
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow
                           
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>

              </Box>
            </div>
          

  );
}