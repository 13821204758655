import React, { useState, useEffect, useRef } from "react";
import Footer from "../../component/Footer";
import Breadcrumb from "./Breadcrumb";
import NeedHelp from "./NeedHelp";
import { TbPointFilled } from "react-icons/tb";
import { Link } from "react-router-dom";
import axios from "axios";
import { decodedStringValue } from "../../_helpers";
import { Helmet } from 'react-helmet';
export default function AnnuaireDesNotaires() {
  /* =====================|Get Default Values|================== */
  const searchRef = useRef(null);
  const [search, setSearch] = useState([]);
  const [checkList, setCheckList] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [paginateData, setPaginateData] = useState({
    "current": 1,
    "total_pages": 0,
    "total_results": 0,
    "size": 100
  });
  const [breadcrumbsData, setBreadcrumbsData] = useState({
    "breadCrumbs":[
      {"pagename":"Annuaires","path":"/notaires"}
    ],
    "main":{"pagename":"Notaires","path":"/"}
  });
  // Search Input data
  function getInputSearchData(input) {
    setSearchKey(input);
    if (input) {
      /* let data = JSON.stringify({
        "query": input,
        "group": {
          "field": "postal_code"
        }
      }); */
      let data = { search: input ,size:10,currentPage:1}
      let config = {
        method: 'post',
        maxBodyLength: Infinity,        
        url: process.env.REACT_APP_API_URL + `api/admin/search_directive_notaires`,
        headers: {
          // 'Authorization': `Bearer ${process.env.REACT_APP_ELK_SEARCH_API_KEY}`,
          // 'Accept': '*/*', 
          // "Cache-Control":"no-cache",
          'Content-Type': 'application/json',
        },
        data: data
      };
      axios.request(config)
        .then((response) => {
          console.log("response.data.data",response.data.data)
          setCheckList(true);
          setSearch(response.data.data);
          /* if ((response.data.data).length > 0) {
            let resultList = response.data.data.filter((val, i) => val._source.locality && val._source.postal_code)
            setSearch(resultList);
            setCheckList(true);
          } else {
            setSearch(response.data.data);
          } */

        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.error(error);
          setCheckList(false);
        });

      /* axios.post(process.env.REACT_APP_API_URL+`api/admin/search_directive_notaires`,{ search: input })
        .then((response) => {
         console.log("response",response);
          // Handle a successful response
          setSearch(response.data.data);
          setCheckList(true);
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.error("error",error);
          setCheckList(false);
        }); */
    } else {
      setCheckList(false);
    }
  }
  // ========================================

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [search, checkList]);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setCheckList(false);
    }
  };
  return (
    <>
     <Helmet>
        <title>Trouvez votre Notaire en France | Annuaire BusyPlace</title>
        <meta name="description" content="Accédez à notre annuaire complet de notaires en France sur BusyPlace et trouvez le professionnel idéal pour vos transactions immobilières, successions, et autres services notariaux. Recherche facile par localisation et spécialité." />
        {/* <link rel="canonical" href={`${process.env.REACT_APP_WEB_URL}/notaires`} /> */}
      </Helmet>
      <div className='w-full h-full AnnuaireBg  pb-6 py-2 md:py-0 md:min-h-[359px]' >
        <div className='md:max-w-[1477px] relative w-full h-full m-auto py-0 md:py-8 px-4 sm:px-14'>
          <div className='flex items-center justify-start sm:gap-4 w-full m-auto'>
            <Breadcrumb page="Notaires" breadcrumbsData={breadcrumbsData} />
          </div>
          <div className='max-w-[1277px] m-auto mt-8 md:mt-16 flex justify-center items-center flex-col gap-4'>
            <h1 className=' text-2xl text-center lg:text-3xl 3xl:text-[35px] font-black text-primary'>L’annuaire des Notaires</h1>
            <div
              style={{
                position: "relative",
                width: '100%',
                margin: '0 auto'
              }}
            >
              <form
                id="searchForm"
                style={{ zIndex:"9" }}
                className="bg-white border-0 mx-auto w-100 max-w-[727px] px-5 py-4 input-box-shadow rounded flex justify-between w-full"
              >
                <input
                  onInput={(e) => getInputSearchData(e.target.value)}
                  type="text"
                  className=" w-full outline-none text-[#797979] text-base font-light"
                  placeholder="Nom de la ville ou du département"
                />
                <Link>
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 35 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M33 33L25.6807 25.6677M29.7368 15.8684C29.7368 19.5466 28.2757 23.074 25.6749 25.6749C23.074 28.2757 19.5466 29.7368 15.8684 29.7368C12.1903 29.7368 8.6628 28.2757 6.06197 25.6749C3.46113 23.074 2 19.5466 2 15.8684C2 12.1903 3.46113 8.6628 6.06197 6.06197C8.6628 3.46113 12.1903 2 15.8684 2C19.5466 2 23.074 3.46113 25.6749 6.06197C28.2757 8.6628 29.7368 12.1903 29.7368 15.8684V15.8684Z"
                      stroke="#27295B"
                      strokeWidth="3"
                      strokeLinecap="round"
                    />
                  </svg>
                </Link>
              </form>
              <div
                style={{ display: checkList ? "block" : "none" }}
                className="searchListBox"
                ref={searchRef}
              >
                <ul>
                  {search.length === 0 ? (
                    <div
                      className="containertype"
                      style={{ textAlign: "left", paddingTop:"9px 19px" }}
                    >
                      <span>No result found!</span>
                      {/* <div className="dottype"></div>
                      <div className="dottype"></div>
                      <div className="dottype"></div> */}
                    </div>
                  ) : (
                    ""
                  )}
                  {search.map((listdata) => {
                    let list= listdata._source;
                    return (
                      <li>
                        <Link
                          className="ctmref"
                          to={"/fiche-notaires/"+decodedStringValue(listdata.slug)+"/" + list.id}
                        >
                          {decodedStringValue(list.name)} ({list.locality}, {list.postal_code})
                        </Link>
                      </li>
                    );
                  })}
                  <li className={search.length>0?"":"hidden"} style={{"fontWeight":"700"}}>
                    <Link
                      onClick={(e)=>{
                        setCheckList(false)
                        setPaginateData({
                          "current": 1,
                          "total_pages": 0,
                          "total_results": 0,
                          "size": 100
                        })
                      }}
                      className="ctmref"
                      // to={`/directory_search_result/notaires/${searchKey}`}
                      to={`/annuaire-des-notaires-selection/notaires/${searchKey}`}
                    >
                      {"Voir tous les résultats de la recherche"}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/*  <form class="bg-white border-0 max-w-[727px] px-5 py-4 input-box-shadow rounded-full flex justify-between w-full">
              <input type="text" class=" w-full outline-none text-[#797979] text-base font-light" placeholder="Nom de la ville ou du département" />
              <Link to='/fiche-notaires'>
                <svg width="28" height="28" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M33 33L25.6807 25.6677M29.7368 15.8684C29.7368 19.5466 28.2757 23.074 25.6749 25.6749C23.074 28.2757 19.5466 29.7368 15.8684 29.7368C12.1903 29.7368 8.6628 28.2757 6.06197 25.6749C3.46113 23.074 2 19.5466 2 15.8684C2 12.1903 3.46113 8.6628 6.06197 6.06197C8.6628 3.46113 12.1903 2 15.8684 2C19.5466 2 23.074 3.46113 25.6749 6.06197C28.2757 8.6628 29.7368 12.1903 29.7368 15.8684V15.8684Z" stroke="#27295B" strokeWidth="3" strokeLinecap="round" />
                </svg>
              </Link>
            </form> */}
          </div>
        </div>

      </div >
      <div className='w-full h-full min-h-[359px]  px-4 sm:px-14' >
        <div className='max-w-[1057px] relative w-full h-full m-auto mt-8 sm:mt-20'>
          <h2 className='font-semibold text-xl md:text-3xl text-primary'>Pourquoi faire appel aux services d’un notaire lors de la création de votre entreprise ?</h2>
          <p className='text-[17px] font-normal text-primary mt-5'>Le notaire vous assiste lors de vos démarches auprès de l'administration. Il vous conseille sur la gestion de votre entreprise et de votre patrimoine et maîtrise le droit des entreprises.</p>

          <p className='text-[17px] font-normal text-primary mt-7'>Voici les points sur lesquels le notaire peut intervenir :</p>
          <ul className=' '>
            <li className='flex gap-2 items-center text-primary'><TbPointFilled className='text-primary text-[6px]' />Le choix de la forme judidique.</li>
            <li className='flex gap-2 items-center text-primary'><TbPointFilled className='text-primary text-[6px]' />La rédaction des statuts juridiques.</li>
            <li className='flex gap-2 items-center text-primary'><TbPointFilled className='text-primary text-[6px]' />L’immatriculation auprès des registres publics. </li>
            <li className='flex gap-2 items-center text-primary'><TbPointFilled className='text-primary text-[6px]' />La protection du patrimoine personnel.</li>
            <li className='flex gap-2 items-center text-primary'><TbPointFilled className='text-primary text-[6px]' />Etablissement du bail commercial.</li>

          </ul>

        </div>
      </div>
      <NeedHelp />
      <Footer />
    </>
  )
}
