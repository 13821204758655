import React,{useState,useEffect,useMemo, useCallback} from 'react'
import axios from 'axios'
import moment from 'moment';
import Sidebar from './component/Sidebar'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Breadcrumbs, Button, Stack,Tabs ,Tab  } from '@mui/material';

import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate,useLocation,Navigate } from 'react-router-dom'
import ToastMessageComp from '../component/ToastMessageComp';
import { alertActions } from '../_actions';
import { LoadingButton } from '@mui/lab';
import {Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle} from '@mui/material';
import PageLoaderComp from '../component/pageLoaderComp';
import { useLogoutHandler } from '../_helpers/logoutHandler';
const breadcrumbs = [
  <Link underline="hover" key="1" color="inherit" href="/">
    Tableau de bord
  </Link>,

  <Typography key="3">
    Fiches professionnelles
  </Typography>,
];


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
 
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const {  order, orderBy, onRequestSort,headCells } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#fff",
      color: "#797979",
      fontSize: '16px',
      fontWeight: '400'
    },

  }));
  return (
    <TableHead >
      <TableRow>

        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'normal' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = { 
  onRequestSort: PropTypes.func.isRequired, 
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};



// tab code start

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function EnhancedTableToolbar(props) {
  let totalClaimData = props.totalClaimData;
  let searchType = props.searchType;
  const handleSearch = (event) => {
    let searchVal = event.target.value;
    if(searchVal.length>0){  
      if(searchType === "accountant"){
        let filteredRows = totalClaimData.resultSearchAcc.filter((row) => {          
          let email = row.claim_json_parse.email?(row.claim_json_parse.email).toLowerCase():"";
          let firstname = row.claim_json_parse.name?(row.claim_json_parse.name).toLowerCase():"";
          let telephone = row.claim_json_parse.telephone?(row.claim_json_parse.telephone).toLowerCase():"";
          return email.includes(searchVal.toLowerCase()) || firstname.includes(searchVal.toLowerCase())  || telephone.includes(searchVal.toLowerCase()) 
        });
        props.setClaimData((prev) => ({ ...prev, ["resultSearchAcc"]: filteredRows }));
        
      }else if(searchType === "avocats"){
        let filteredRows = totalClaimData.resultSearchAvocats.filter((row) => {          
          let email = row.claim_json_parse.email?(row.claim_json_parse.email).toLowerCase():"";
          let firstname =row.claim_json_parse.avNom?(row.claim_json_parse.avNom).toLowerCase():"";
          let lastname = row.claim_json_parse.avPrenom?(row.claim_json_parse.avPrenom).toLowerCase():"";
          let full_name = firstname+" "+lastname;
          let telephone = row.claim_json_parse.telephone?(row.claim_json_parse.telephone).toLowerCase():"";
          /* let ville = (row.ville).toLowerCase(); */
          return email.includes(searchVal.toLowerCase()) || firstname.includes(searchVal.toLowerCase()) || lastname.includes(searchVal.toLowerCase())  || full_name.includes(searchVal.toLowerCase())  || telephone.includes(searchVal.toLowerCase())
        });
        props.setClaimData((prev) => ({ ...prev, ["resultSearchAvocats"]: filteredRows }));

      }else if(searchType === "notairs"){
        let filteredRows = totalClaimData.resultSearchNotairs.filter((row) => {  
          let email = row.claim_json_parse.email?(row.claim_json_parse.email).toLowerCase():"";
          let firstname = row.claim_json_parse.name?(row.claim_json_parse.name).toLowerCase():"";
          let telephone = row.claim_json_parse.phone?(row.claim_json_parse.phone).toLowerCase():"";
          /* let ville = (row.ville).toLowerCase(); */
          return email.includes(searchVal.toLowerCase()) || firstname.includes(searchVal.toLowerCase())  || telephone.includes(searchVal.toLowerCase())
        });
        props.setClaimData((prev) => ({ ...prev, ["resultSearchNotairs"]: filteredRows }));
        
      }else{
        props.setClaimData({
          resultSearchAcc: props.totalClaimData.resultSearchAcc,
          resultSearchAvocats:props.totalClaimData.resultSearchAvocats,
          resultSearchNotairs:props.totalClaimData.resultSearchNotairs,
        })
      }
     
    }else{
      props.setClaimData({
        resultSearchAcc: props.totalClaimData.resultSearchAcc,
        resultSearchAvocats:props.totalClaimData.resultSearchAvocats,
        resultSearchNotairs:props.totalClaimData.resultSearchNotairs,
      })

    }
  };
  return (

    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },

      }}
    >
      <Typography
        sx={{ flex: '1 1 100%', marginTop: '15px' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <Paper
          component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            inputProps={{ 'aria-label': 'search ' }}
            onInput={handleSearch}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" disabled>
            <SearchIcon />
          </IconButton>
        </Paper>
      </Typography>

      {/* <Tooltip title="Filter list">
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip> */}
    </Toolbar>
  );
}

export default function Claims() {
  const logoutHandler = useLogoutHandler({logoutfrom:"admin"});
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState("");
  const [dialogStatus, setDialogeStatus] = useState("");
  const [confirmType, setConfirmType] = useState("");
  const [claimType, setClaimType] = useState("");
  const [dialoge, setDialoge] = useState({"title":"","msg":""});
  
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
 
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [headCells, setHeadCells] = useState([
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'languages',
      numeric: false,
      disablePadding: false,
      label: 'Languages',
    },
    {
      id: 'skills',
      numeric: false,
      disablePadding: false,
      label: 'Skills',
    },
    {
      id: 'telephone',
      numeric: true,
      disablePadding: false,
      label: 'Ttéléphone',
    },
    {
      id: 'streetaddress',
      numeric: true,
      disablePadding: false,
      label: 'Adresse',
    },
    {
      id: 'DateCreation',
      numeric: true,
      disablePadding: false,
      label: 'Date création',
    },
    {
      id: 'Actions',
      numeric: true,
      disablePadding: false,
      label: 'Actions',
    },
  ]);
  const [rows, setRows] = useState([]);
  const [claimData, setClaimData] = useState({
    resultSearchAcc:[],
    resultSearchAvocats:[],
    resultSearchNotairs:[],
  });
  const [totalClaimData, setTotalClaimData] = useState({
    resultSearchAcc:[],
    resultSearchAvocats:[],
    resultSearchNotairs:[],
  });

  const deleteRow = useCallback((category, id) => {
    setClaimData(prevClaimData => {
      const updatedCategoryData = prevClaimData[category].filter(item => item._id !== id);
      return { ...prevClaimData, [category]: updatedCategoryData };
    });
  }, []);
  // const [visibleRows, setvisibleRows] = useState([]);
  const visibleRowsAcc = useMemo(
    () =>
      stableSort(claimData.resultSearchAcc, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage,claimData.resultSearchAcc],
  );

  const visibleRowsNotairs = useMemo(
    () =>
      stableSort(claimData.resultSearchNotairs, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage,claimData.resultSearchNotairs],
  );

  const visibleRowsAvocats = useMemo(
    () =>
      stableSort(claimData.resultSearchAvocats, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage,claimData.resultSearchAvocats],
  );

  // tab code 
  const [value, setValue] = useState(0);

  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  // tab code  end
  useEffect(() => {
    getAllClaims();
  }, []);

  useEffect(() => {
    if(dialogStatus==="approved" || dialogStatus==="approve"){
      approvedClaim();
    }
    if(dialogStatus==="delete"){
      deleteClaim();
    }
  }, [dialogStatus]);

  const handleClickOpen = (e,rowData,type,claimTypeGet) => {
    setConfirmType(type);
    setClaimType(claimTypeGet);
    setOpen(true);
    setSelectedRowData(rowData);
    if(type === "approve"){
      setDialoge({"title":"Approuver la réclamation","msg":"Etes-vous sûr de vouloir approuver ?"});

    }else if(type === "delete"){
      setDialoge({"title":"Supprimer la réclamation","msg":"Etes-vous sûr de vouloir supprimer ?"});
    }else{
      setDialoge({"title":"","msg":""});
    }
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - claimData.resultSearchAcc.length) : 0;

  

  async function getAllClaims(){   
    setPageLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/admin/get-claim-verification`);
      setPageLoading(false)
      setClaimData({
        resultSearchAcc:response.data.data.resultSearchAcc,
        resultSearchAvocats:response.data.data.resultSearchAvocats,
        resultSearchNotairs:response.data.data.resultSearchNotairs,
      })
      setTotalClaimData({
        resultSearchAcc:response.data.data.resultSearchAcc,
        resultSearchAvocats:response.data.data.resultSearchAvocats,
        resultSearchNotairs:response.data.data.resultSearchNotairs,
      })
      setRows(response.data.data);
      
    } catch (error) {
      console.error(error);
      setSuccessful(true);
      dispatch(alertActions.error(error.response.data.msg));
      setLoading(false);
      setPageLoading(false)    
      // logoutHandler();  
      
    }
  };
 
  const currentAdmin = useSelector(state => state.administrator.admin);
  const alert = useSelector(state => state.alert);
  if (!currentAdmin) {
    return <Navigate to="/admin/login" />;
  } 
  const deleteClaim= async()=>{
    setSuccessful(false);
    setLoadingDelete(true);
    try {
      let sendData = {
        // documentId: selectedRowData.claim_json_parse.documentId,
        documentId: selectedRowData._id,
        accountant_id: selectedRowData.claim_json_parse.id,
        id: selectedRowData.claim_json_parse.id,
        type: claimType
      }
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/delete-claim`, sendData);

      setSuccessful(true);
      setLoadingDelete(false);  
      if(response.data.sucess === false){
        dispatch(alertActions.error(response.data.msg));       
      }else{
        dispatch(alertActions.success(response.data.msg)); 
        // getAllClaims(); 
        if(claimType === "accountant"){
          deleteRow('resultSearchAcc', selectedRowData._id)   

        }
        if(claimType === "avocats"){
          deleteRow('resultSearchAvocats', selectedRowData._id)   

        }
        if(claimType === "notairs"){
          deleteRow('resultSearchNotairs', selectedRowData._id)   

        }
        handleClose(); 
        setConfirmType(""); 
      }
          
      
    } catch (error) {
      console.error(error);    
      setSuccessful(true);
      dispatch(alertActions.error(error.response.data.msg));
      setLoading(false);
      setPageLoading(false)    
      // logoutHandler();    
    }
  }
  const approvedClaim=async()=>{
   /*  console.log("selectedRowData",selectedRowData,claimData,claimType)
   
    return; */
    setSuccessful(false);
    setLoading(true);
    setPageLoading(true)  
    try {
      let sendData = {
        documentId: selectedRowData._id,
        accountant_id: selectedRowData.claim_json_parse.id,
        id: selectedRowData.claim_json_parse.id,
        type: claimType
      }
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/admin/claim-verification`, sendData);  
      if(response.data.sucess === true){
        dispatch(alertActions.success(response.data.msg));  
        getAllClaims();
        // row.claim_verified
        handleClose();  
               
      }else{
        dispatch(alertActions.error(response.data.msg));
             
      }  
      setSuccessful(true);
      setLoading(false);  
      setPageLoading(false);
      
    } catch (error) {
      console.error(error);  
      setSuccessful(true);
      dispatch(alertActions.error(error.response.data.msg));
      setLoading(false);
      setPageLoading(false)    
      logoutHandler();  
    }
  }

  return (    
    <div className='bg-mainBg w-full '>
      {successful ?
        <ToastMessageComp message={alert.message} type={alert.type} />
        : ""}
        {pageLoading ?
        <PageLoaderComp />
        : ""}
     
            <div className=' w-full h-full bg-white p-8'>
              <Typography variant="h4" component="h4" fontWeight={500} color={'#1CBE93'}>Fiches professionnelles</Typography>
              <Stack spacing={2} mb={3} pb={3} sx={{ borderBottom: '0.5px solid rgb(222, 227, 226)' }}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                  {breadcrumbs}
                </Breadcrumbs>

              </Stack>
              {/* <Typography variant="h6" component="h6" fontWeight={400} mb={1} color={'#27295B'}>Tous les utilisateurs</Typography> */}
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" indicatorColor={"primary"}
      textColor="primary"  >
                    <Tab label="Experts-comptables" {...a11yProps(0)}  className="customTab"  />
                    <Tab label="Avocats" {...a11yProps(1)}  className="customTab"  />
                    <Tab label="Notaires" {...a11yProps(2)}  className="customTab"  />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0} >
                  <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                      <EnhancedTableToolbar  searchType={"accountant"} totalClaimData={totalClaimData} setTotalClaimData={setTotalClaimData} setClaimData={setClaimData}   />
                      <TableContainer sx={{ marginTop: "15px" }}>
                        <Table
                          sx={{ minWidth: 750 }}
                          aria-labelledby="tableTitle"
                        >
                          <EnhancedTableHead 
                            order={order}
                            orderBy={orderBy} 
                            onRequestSort={handleRequestSort}
                            rowCount={claimData.resultSearchAcc.length}
                            headCells={headCells}
                          />
                          <TableBody>
                            {visibleRowsAcc.map((row, index) => {

                              return (
                                <TableRow
                                  hover  
                                  role="checkbox" 
                                  tabIndex={-1}
                                  key={row.name}  
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                    {row.claim_json_parse?row.claim_json_parse.name:""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                  {row.claim_json_parse?row.claim_json_parse.email:""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                  {row.claim_json_parse?row.claim_json_parse.languages:""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                    {row.claim_json_parse?row.claim_json_parse.skills:""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                    {row.claim_json_parse?row.claim_json_parse.telephone:""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                  {row.claim_json_parse?`${row.claim_json_parse.streetaddress} ${row.claim_json_parse.addresslocality}, ${row.claim_json_parse.postalcode}`:""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{moment(row._source.created_at).format("LL")}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                    <Button component={Link} to={`/admin/claimdetails/accountant/${row.claim_json_parse.id}`} variant="contained" color="primary" className=' me-2' size="small">Afficher</Button>
                                   {
                                     row.claim_verified?
                                     <Button size="small" variant="contained" color="success" className=' me-2' disabled >Vérifié</Button>
                                    :
                                    <LoadingButton
                                      size="small"
                                      onClick={(e)=>handleClickOpen(e,row,"approve","accountant")}
                                      // startIcon={<SaveIcon />}
                                      loading={loading}
                                      loadingPosition="start"
                                      variant="contained"
                                      className=' me-2'                                    >
                                      <span>Approuver</span>
                                    </LoadingButton>
                                    }

                                    <LoadingButton
                                      size="small"
                                      onClick={(e)=>handleClickOpen(e,row,"delete","accountant")}
                                      // onClick={approvedClaim}
                                      // loading={loading}
                                      // loadingPosition="start"
                                      variant="contained"
                                      color="error"
                                      className={!row.claim_verified?'me-2':"hidden me-2"}
                                      startIcon={<DeleteIcon />} 
                                    >
                                      <span>Supprimer</span>
                                    </LoadingButton>
                                    {/* <Button variant="outlined" color="error" className='' sx={{ mx: '2px' }} >Supprimer</Button> */}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                            {emptyRows > 0 && (
                              <TableRow
                              
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={claimData.resultSearchAcc.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                   <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                      <EnhancedTableToolbar searchType={"avocats"} totalClaimData={totalClaimData} setTotalClaimData={setTotalClaimData} setClaimData={setClaimData}   />
                      <TableContainer sx={{ marginTop: "15px" }}>
                        <Table
                          sx={{ minWidth: 750 }}
                          aria-labelledby="tableTitle"
                        
                        >
                          <EnhancedTableHead 
                            order={order}
                            orderBy={orderBy} 
                            onRequestSort={handleRequestSort}
                            rowCount={claimData.resultSearchAvocats.length}
                            headCells={headCells}
                          />
                          <TableBody>
                            {visibleRowsAvocats.map((row, index) => {

                              return (
                                <TableRow
                                  hover  
                                  role="checkbox" 
                                  tabIndex={-1}
                                  key={row.name}  
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                    {row.claim_json_parse?(row.claim_json_parse.avNom).toLowerCase()+" "+(row.claim_json_parse.avPrenom).toLowerCase():""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                  {row.claim_json_parse?row.claim_json_parse.email:""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                  {row.claim_json_parse?row.claim_json_parse.avLang:""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                    {row.claim_json_parse?row.claim_json_parse.skills:""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                    {row.claim_json_parse?row.claim_json_parse.telephone:""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                  {row.claim_json_parse?`${row.claim_json_parse.cbAdresse1} ${row.claim_json_parse.cbAdresse2}  ${row.claim_json_parse.cbVille}, ${row.claim_json_parse.cbCp}`:""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{moment(row._source.created_at).format("LL")}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                    <Button component={Link} to={`/admin/claimdetails/avocats/${row.claim_json_parse.avocats_id}`} variant="contained" color="primary" className=' me-2' size="small">Afficher</Button>
                                   {
                                     row.claim_verified?
                                     <Button size="small" variant="contained" color="success" className=' me-2' disabled >Vérifié</Button>
                                    :
                                    <LoadingButton
                                      size="small"
                                      onClick={(e)=>handleClickOpen(e,row,"approve","avocats")}
                                      // startIcon={<SaveIcon />}
                                      loading={loading}
                                      loadingPosition="start"
                                      variant="contained"
                                      className=' me-2'                                    >
                                      <span>Approuver</span>
                                    </LoadingButton>
                                    }

                                    <LoadingButton
                                      size="small"
                                      onClick={(e)=>handleClickOpen(e,row,"delete","avocats")}
                                      // onClick={approvedClaim}
                                      // loading={loading}
                                      // loadingPosition="start"
                                      variant="contained"
                                      color="error"
                                      className={!row.claim_verified?'me-2':"hidden me-2"}
                                      startIcon={<DeleteIcon />} 
                                    >
                                      <span>Supprimer</span>
                                    </LoadingButton>
                                    {/* <Button variant="outlined" color="error" className='' sx={{ mx: '2px' }} >Supprimer</Button> */}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                            {emptyRows > 0 && (
                              <TableRow
                              
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={claimData.resultSearchAvocats.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                   <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                      <EnhancedTableToolbar  searchType={"notairs"} totalClaimData={totalClaimData} setTotalClaimData={setTotalClaimData} setClaimData={setClaimData} />
                      <TableContainer sx={{ marginTop: "15px" }}>
                        <Table
                          sx={{ minWidth: 750 }}
                          aria-labelledby="tableTitle"
                        
                        >
                          <EnhancedTableHead 
                            order={order}
                            orderBy={orderBy} 
                            onRequestSort={handleRequestSort}
                            rowCount={claimData.resultSearchNotairs.length}
                            headCells={headCells}
                          />
                          <TableBody>
                            {visibleRowsNotairs.map((row, index) => {

                              return (
                                <TableRow
                                  hover  
                                  role="checkbox" 
                                  tabIndex={-1}
                                  key={row.name}  
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                    {row.claim_json_parse?row.claim_json_parse.name:""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                  {row.claim_json_parse?row.claim_json_parse.email:""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                  {row.claim_json_parse?(JSON.parse(row.claim_json_parse.spoken_languages)).join(","):""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                    {row.claim_json_parse?row.claim_json_parse.skills:""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                    {row.claim_json_parse?row.claim_json_parse.phone:""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                  {row.claim_json_parse?`${row.claim_json_parse.address_line} ${row.claim_json_parse.locality}, ${row.claim_json_parse.postal_code}`:""}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{moment(row._source.created_at).format("LL")}</TableCell>
                                  <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                    <Button component={Link} to={`/admin/claimdetails/notairs/${row.claim_json_parse.id}`} variant="contained" color="primary" className=' me-2' size="small">Afficher</Button>
                                   {
                                     row.claim_verified?
                                     <Button size="small" variant="contained" color="success" className=' me-2' disabled >Vérifié</Button>
                                    :
                                    <LoadingButton
                                      size="small"
                                      onClick={(e)=>handleClickOpen(e,row,"approve","notairs")}
                                      // startIcon={<SaveIcon />}
                                      // loading={loading}
                                      // loadingPosition="start"
                                      variant="contained"
                                      className=' me-2'                                    >
                                      <span>Approuver</span>
                                    </LoadingButton>
                                    }

                                    <LoadingButton
                                      size="small"
                                      onClick={(e)=>handleClickOpen(e,row,"delete","notairs")}
                                      // onClick={approvedClaim}
                                      // loading={loading}
                                      // loadingPosition="start"
                                      variant="contained"
                                      color="error"
                                      className={!row.claim_verified?'me-2':"hidden me-2"}
                                      startIcon={<DeleteIcon />} 
                                    >
                                      <span>Supprimer</span>
                                    </LoadingButton>
                                    {/* <Button variant="outlined" color="error" className='' sx={{ mx: '2px' }} >Supprimer</Button> */}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                            {emptyRows > 0 && (
                              <TableRow
                              
                              >
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={claimData.resultSearchNotairs.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  </Box>
                </CustomTabPanel>
              </Box>
              
            </div>
           
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialoge.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialoge.msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <LoadingButton
            size="small"
            onClick={(e)=>setDialogeStatus(confirmType)}
            // startIcon={<SaveIcon />}
            loading={loading}
            loadingPosition="start"
            // variant="contained"
            className=' me-2'                                    >
            <span>Yes</span>
          </LoadingButton>
         {/*  <Button onClick={(e)=>setDialogeStatus(confirmType)} autoFocus>
            Yes
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>

  );
}
