/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { authHeader } from "../_helpers";
import axios from "axios";
import moment from "moment";
import SideBar from "./SideBar";
import "./Style.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Tab,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Footer from "../component/Footer";
import { Link, Navigate, useParams } from "react-router-dom";
import SearchHead from "./SearchHead";
import { useSelector, useDispatch } from "react-redux";
import { orderActions } from "../_actions";
import { alertActions } from "../_actions";
import { changeDateFormatFrench, convertNumberFrench } from "../_helpers";
import { MdModeEditOutline } from "react-icons/md";
import { BsCreditCard2Back } from "react-icons/bs";
import { HiOutlineDownload } from "react-icons/hi";

import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PageLoaderComp from "../component/pageLoaderComp";
import ToastMessageComp from "../component/ToastMessageComp";
import ScreenWrapper from "./ScreenWrapper";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
export default function AnnoncesPublieesDetails() {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const params = useParams();
  const currentUser = useSelector((state) => state.authentication.user);
  const orders = useSelector((state) => state.orders.devis);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [tabValue, setTabValue] = useState("AnnoncePubliee");
  const [commentValue, setCommentValue] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [TabHead, setTabHead] = useState([
    "DENOMINATION",
    "FORMULAIRE",
    "JOURNAL",
    "MONTANT",
    "DATE DE CRÉATION",
    "INVOICE",
    "ATTESTATION",
    "ATTESTATION",
  ]);
  const [TabData, setTabData] = useState([]);
  const [annonceDetails, setAnnonceDetails] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getDeviseDetails(params.id);
  }, []);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - TabData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadAds = async (event, annonceDetails) => {
    setPageLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/announce/generateAdsPdf/${annonceDetails.data.id}`
      );
      // console.log(" handleDownloadAds response.data",response.data.data)
      if (response.data.sucess) {
        let adsUrl = `${process.env.REACT_APP_API_URL}uploads/pdfgenerate/${response.data.data.pdfname}`;
        window.open(adsUrl, "_blank");
      }
      setPageLoading(false);
    } catch (error) {
      console.error(error);
      setPageLoading(false);
    }
  };

  const getDeviseDetails = async (id) => {
    setPageLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/announce/announce/${id}`
      );
      // console.log("response.data",response.data)
      if (response.data.data) {
        response.data.data.invoiceUrl = `${process.env.REACT_APP_API_URL}uploads/pdfgenerate/invoice/${response.data.data.votre_pdf}`;
        response.data.data.attestationUrl = `${process.env.REACT_APP_API_URL}uploads/pdfgenerate/attestation/${response.data.data.attestation}`;
      }
      setAnnonceDetails(response.data);

      setPageLoading(false);
    } catch (error) {
      console.error(error);
      setPageLoading(false);
    }
  };
  const handleSubmitComment = async (event) => {
    setSuccessful(false);
    // console.log("commentValue.length",commentValue.length)
    if (commentValue.length <= 0) {
      dispatch(alertActions.error("Veuillez saisir un commentaire!"));
      setSuccessful(true);
      return;
    }
    setPageLoading(true);

    try {
      let sendData = {
        announce_id: params.id,
        comment: commentValue,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/announce/createAnnounceComments`,
        sendData
      );

      setSuccessful(true);
      setPageLoading(false);
      if (response.data.sucess === false) {
        dispatch(alertActions.error(response.data.msg));
      } else {
        setCommentValue("");
        dispatch(alertActions.success(response.data.msg));
      }
    } catch (error) {
      setPageLoading(false);
      console.error(error);
    }
  };
  const handleChangeComment = (event) => {
    setCommentValue(event.target.value);
  };
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  if (!currentUser) {
    return <Navigate to="/" />;
  }
  return (
    <>
      {successful ? (
        <ToastMessageComp message={alert.message} type={alert.type} />
      ) : (
        ""
      )}
      {pageLoading ? <PageLoaderComp /> : ""}
      <ScreenWrapper>
        <div className="max-w-[1126px] w-full h-full bg-white rounded-[20px]">
          <div className="mainHead min-h-[80px] sm:min-h-[120px] flex sm:flex-row flex-col sm:items-center p-3 sm:p-10 sm:justify-between">
            <div>
            <h1 className="text-lg sm:text-2xl xl:text-2xl 2xl:text-[25px] font-black text-white sm:mt-0 mt-4">
                Annonce légale
              </h1>
              <p className="font-montserrat text-18px font-medium leading-55px text-left  text-white sm:mt-0 mt-4">
                n°{" "}
                {annonceDetails &&
                  annonceDetails.data &&
                  annonceDetails.data.invoice_number}
              </p>
            </div>
            <div className="w-full sm:w-auto flex justify-end">
              <SearchHead />
            </div>
          </div>

          <div className="w-full mt-6 sm:-mt-11 sm:px-10 px-4 AnnonTab">
            <TabContext value={tabValue}>
              <Box className='paramTabBar'>
                <TabList
                  indicatorColor="none"
                  onChange={handleChangeTab}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Annonce publiée"
                    value="AnnoncePubliee"
                    className="lg:w-[210px] mt-5 mb-2 mr-2 sideShadow h-[50px] rounded-[10px] bg-white flex items-center justify-center"
                  />
                  <Tab
                    label="Attestation"
                    value="Attestation"
                    className="lg:w-[210px] mt-5  mb-2 mx-2 sideShadow h-[50px] rounded-[10px] bg-white flex items-center justify-center"
                  />
                  <Tab
                    label="Facture"
                    value="Facture"
                    className="lg:w-[210px] mt-5  mb-2 mx-2 sideShadow h-[50px] rounded-[10px] bg-white flex items-center justify-center"
                  />
                  <Tab
                    label="Commentaire"
                    value="Commentaire"
                    className="lg:w-[210px] mt-5  mb-2 mx-2 sideShadow h-[50px] rounded-[10px] bg-white flex items-center justify-center"
                  />
                </TabList>
              </Box>
              <TabPanel value="AnnoncePubliee">
                {/* <MonEntreprise /> */}
                <div
                  className=" rounded-[5px] p-5 sm:mx-0 mx-2"
                  style={{ border: "1px solid #DEE3E2" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      annonceDetails &&
                      annonceDetails.data &&
                      annonceDetails.data.votre_html
                        ? annonceDetails.data.votre_html
                        : "",
                  }}
                ></div>
                <div>
                  <button
                    type="button"
                    className="m-3 mx-auto text-base font-semibold text-white flex items-center justify-center px-5 py-2   rounded-md bg-primary"
                    onClick={(e) => handleDownloadAds(e, annonceDetails)}
                  >
                    {" "}
                    <HiOutlineDownload className="w-5 h-5" /> Télécharger{" "}
                  </button>
                </div>
              </TabPanel>
              <TabPanel value="Attestation">
                {/* <MesDocuments /> */}
                <div style={{ height: "100vh" }}>
                  <iframe
                    src={
                      annonceDetails &&
                      annonceDetails.data &&
                      annonceDetails.data.attestationUrl
                    }
                    width="100%"
                    height="100%"
                    style={{ border: "none" }}
                    title="PDF Viewer"
                  />
                </div>
              </TabPanel>
              <TabPanel value="Facture">
                {/* <MesDocuments /> */}
                <div style={{ height: "100vh" }}>
                  <iframe
                    src={
                      annonceDetails &&
                      annonceDetails.data &&
                      annonceDetails.data.invoiceUrl
                    }
                    width="100%"
                    height="100%"
                    style={{ border: "none" }}
                    title="PDF Viewer"
                  />
                </div>
              </TabPanel>
              <TabPanel value="Commentaire">
                {/* <MesPaiements /> */}
                <div>
                  <TextField
                    id="CommentaireText"
                    className="w-full"
                    multiline={true}
                    minRows={6}
                    label=""
                    placeholder={`Laissez-nous un commentaire sur votre expérience sur Legals.`}
                    name="comment"
                    onChange={handleChangeComment}
                    value={commentValue}
                  />
                </div>
                <div>
                  <button
                    className="m-3 mr-0 float-right text-base font-semibold text-white flex items-center justify-center px-5 py-2  rounded-md bg-primary"
                    onClick={handleSubmitComment}
                  >
                    {" "}
                    Envoyer
                  </button>
                </div>
              </TabPanel>
            </TabContext>
          </div>
        </div>
        <div>
          <Link
            className="w-[200px] m-3  text-base font-semibold text-white flex items-center justify-center px-5 py-2   rounded-md bg-primary"
            to={"/userdashboard/annonces-publiees"}
          >
            {" Retour à la liste"}
          </Link>
        </div>
      </ScreenWrapper>
      <Footer />
    </>
  );
}
